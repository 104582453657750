import { graphql } from "gatsby"

import MDXRenderer from "gatsby-plugin-mdx/mdx-renderer"

// import { useIsAuthenticated } from "@azure/msal-react"

import SEO, { getSeoProps } from "../components/Common/SEO"

import { RelatedSectionProps, RelatedSection } from "../components/Sections/RelatedSection"

import HeroTopicPage from "../components/Hero/HeroTopicArticlePage"
import getBannerProps from "../components/Hero/getHeroBannerProps"

import getMainPath from "../components/Common/MainPaths"
// import getAuthor from "../components/Common/getAuthor"

/**
 * @description Bygges opp likt som de andre sidene, oversikt, ingress, visning av kort
 * kort sorteres etter nyeste først (created date)
 * Her viser vi da EN rad med kort
 * 1. Feature artikler, sortert på nyeste først
 * vis alle feature artikler vi har.
 */

const TopicTemplate = ({ location, data }) => {
  const mainPath = getMainPath("topics")

  const { contentfulTopics: content } = data

  // TODO: Add test for empty array. this will happen when now articles are related to a topic.
  const allContent = content.article

  // Parse the content and create the props needed by SEO component.
  const helmetProps = getSeoProps(content, location, "article")

  // Parse the content, and get the needed banner props
  const heroBannerProps = getBannerProps(content, location.href)

  const relatedProps: RelatedSectionProps = {
    title: "Les mer om: " + content.title,
    path: "nb" + mainPath,
    data: allContent,
  }

  return (
    <>
      <SEO {...helmetProps} />
      <main>
        <article>
          <div className="bg-white overflow-hidden max-w-240 mx-auto ">
            {/* Bør kanskje være begrenset til mindre bredde for å ta seg bedre ut på større skjermer, men max-w-7l er muligens litt for smalt tll wide hero */}
            <HeroTopicPage {...heroBannerProps} />
            <div className="bg-white relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mb-48">
              <div className="mx-auto max-w-screen md:max-w-2xl pl-4 md:px-8 lg:max-w-3xl  xl:max-w-116">
                {content.body?.childMdx?.body ? (
                  <MDXRenderer>{content.body.childMdx.body}</MDXRenderer>
                ) : (
                  "Body mangler i contentful"
                )}
              </div>
            </div>
          </div>
        </article>
      </main>
      <RelatedSection {...relatedProps} />
    </>
  )
}

export default TopicTemplate

export const topicQuery = graphql`
  query topicsQuery($slug: String!) {
    contentfulTopics(
      slug: { eq: $slug }
      node_locale: { eq: "nb-NO" }
      article: { elemMatch: { public: { eq: true }, articleType: { eq: true } } }
    ) {
      title
      id
      contentful_id
      metadataDescription
      slug
      node_locale
      createdAt(formatString: "DD.MM.YYYY hh:mm:ss")
      updatedAt(formatString: "DD.MM.YYYY hh:mm:ss")
      author {
        name
        firstName
        lastName
        twitter
        twitterId
      }
      heroImage {
        id
        public_id
        context {
          custom {
            Credits
            alt
          }
        }
        url
        secure_url
        width
        height
        format
      }
      ingress {
        ingress
        childMdx {
          timeToRead
        }
      }
      body {
        childMdx {
          timeToRead
          body
        }
      }
      article {
        id
        slug
        title
        updatedAt(formatString: "DD.MM.YYYY hh:mm:ss")
        createdAt(formatString: "DD.MM.YYYY hh:mm:ss")
        tags {
          id
        }
        category {
          id
          category
          slug
        }
        videoUrl
        heroImage {
          id
          public_id
          context {
            custom {
              Credits
              alt
            }
          }
          url
          secure_url
          width
          height
          format
        }
        ingress {
          ingress
          childMdx {
            timeToRead
          }
        }
        body {
          childMdx {
            body
            timeToRead
          }
        }
      }
      author {
        name
        firstName
        lastName
        twitter
        twitterId
      }
    }
  }
`
