import { HeroWide } from "./HeroImageHandlers"
import { BackArrowButton } from "../Graphics/Arrow"
import { HeroTitleTopics } from "../Styling/Titles"
import { Ingress } from "../Styling/Body"
import { toDisplayDate } from "../Common/DateConverter"
import ShareModal from "../Modals/ShareModal"

import HeroBannerProps from "../Hero/HeroTypes"
import getMainPath from "../Common/MainPaths"

const HeroTopicPage = (props: HeroBannerProps) => {
  const mainPath = getMainPath("topics")

  let date = props.createdAt || ""
  if (props.updatedAt !== undefined && props.updatedAt !== null) date = props.updatedAt
  if (props.createdAtModified !== undefined && props.createdAtModified !== null) date = props.createdAtModified

  const prettyDate = toDisplayDate(date)

  return (
    <>
      <header>
        <div className=" max-w-240 mx-auto">
          <div className="relative grid grid-flow-col grid-cols-1 grid-rows-2 sm:grid-rows-1 sm:grid-cols-2 md:grid-rows-1 md:grid-cols-2 lg:grid-rows-1 lg:grid-cols-2 gap-0">
            <div className="sm:col-span-2 ">
              <div className="h-auto max-h-128 md:max-h-184 overflow-hidden ">
                <HeroWide {...props} />
              </div>
            </div>

            <div className="flex h-auto  max-h-128 justify-center items-center bg-knapplysgronn-500 sm:absolute sm:bottom-0 sm:left-0 sm:right-0 sm:top-0 opacity-90 mx-0 sm:mx-auto sm:my-10 md:my-20 lg:my-28 sm:max-w-md  md:max-w-lg lg:max-w-4xl  xl:max-w-4xl  2xl:max-w-4xl  ">
              <div className="text-center break-normal">
                <div className="hidden items-center sm:contents z-30  ">
                  <BackArrowButton url={mainPath} title="Se alle temaer" />
                </div>
                <div className="mt-0 p-0 sm:mt-12  ">
                  <HeroTitleTopics>{props.title}</HeroTitleTopics>
                </div>
              </div>
            </div>
          </div>

          <div className="bg-white relative mx-auto ">
            <div className="text-center mt-16  text-author-500 text-opacity-50 ">
              <p>
                Av: {props.author}&nbsp; Foto: {props.image.imageCredits} <br /> Sist oppdatert: {prettyDate}
                <br />
                <ShareModal title={props.title} url={props.href} className="my-3" />
              </p>
            </div>

            <div className=" px-4 sm:px-0 max-w-s sm:max-w-lg md:max-w-xl xl:max-w-84 mx-auto mt-16">
              <Ingress>{props.ingress}</Ingress>
            </div>
          </div>
        </div>
      </header>
    </>
  )
}

export default HeroTopicPage
